body {
    font-family: 'Roboto Mono', monospace;
}

/* NOTES STYLES */

.notes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
}

.notes-title,
.notes-count {
    color: var(--color-main);
    font-size: 24px;
    font-weight: 600;
}

.notes-count {
    font-size: 18px;
    color: var(--color-gray);
}


.notes-list {
    padding: 0;
    margin: 16px 0;
    height: 70vh;
    overflow-y: auto;
    scrollbar-width: none;
    /* Firefox */
}

.notes-list::-webkit-scrollbar {
    display: none;
}

ul {
    list-style-type: none;
    padding: 0px 10px;
}

li {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: nowrap;
}

.delete_icon {
    margin-top: 15px;
    cursor: pointer;
}
.delete_icon:hover {
    color: red;
}
.notes-list-item {
    border-bottom: 1px solid var(--color-border);
    padding: 8px 8px;
    background: transition;
    transition: all 0.2s ease-in-out;
}


.notes-list-item h3,
.notes-list-item p span {
    font-weight: 600;
}


.notes-list-item p span {
    color: var(--color-gray);
    display: inline-block;
    margin-right: 8px;
}

.notes-list-item p {
    font-size: 14px;
    color: var(--color-light);
}

.floating-button {
    font-size: 48px;
    position: absolute;
    bottom: 24px;
    right: 16px;
    background: red;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}


.floating-button>svg {
    fill: white;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}